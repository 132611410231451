import React, { useContext } from 'react';

import { Divider, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';

import { postJsonData } from "../../comm/ApiComm";
import { ApiAnswer } from '../../models/ApiAnswer';
import { JsonData } from '../../models/JsonData';
import { UserContext } from '../../contexts/UserContext';
import { useMode } from '../../components/layout/MainLayout';
import { JsonViewer } from '@textea/json-viewer'

type Props = {};

const JsonPage = (props: Props) => {
  const [jsonData, setJsonData] = React.useState<ApiAnswer<JsonData>>();
  const [json, setJson] = React.useState<Object>();
  const [error, setError] = React.useState<string>("");

  const user = useContext(UserContext);
  const { notifyMessage } = useMode();

  const handleJsonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    var obj = {};

    try {
      obj = JSON.parse(e.target.value);
      
      setError("");
    } catch (error: any) {
      setError(error.message);      
    }

    setJson(obj);
  }

  return (
    <>
      <h1>JSON Utilities</h1>
      <Divider />
      <p>Parses the text and presents it in the JSON formatting. The utility can be used for JSON syntax checking as well.</p>
      <div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            const obj = Object.fromEntries(new FormData(event.currentTarget));
            postJsonData(user, obj, setJsonData, notifyMessage);
          }}
        >
          <div>
            <Grid container spacing={2} sx={{ paddingTop: "1em", paddingBottom: "1em" }}>
              <Grid size={6}>
                <Typography variant="h6">Raw Text</Typography>
                <TextField
                  placeholder="Paste your JSON document..."
                  multiline
                  rows={20}
                  sx={{ width: "100%" }}
                  name="string"
                  onChange={handleJsonChange}
                />
              </Grid>
              <Grid size={6}>
                <Typography variant="h6">Formatted JSON</Typography>
                {error.length == 0 ?
                  <JsonViewer
                    value={json}
                    displayDataTypes={false}
                    highlightUpdates={true}
                  />
                  :
                  <p>{error}</p>
                }
              </Grid>
            </Grid>

          </div>
        </form>
      </div>

      { /*
      <div>
        <h2>Statistics</h2>
        {"depth, num of nodes, unique names, ..."}
      </div>
      */ }
    </>

  );
};

export default JsonPage;