import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Clip from '../../components/Clip';
import { postHashData } from "../../comm/ApiComm";
import { ApiAnswer } from '../../models/ApiAnswer';
import { HashData } from '../../models/HashData';
import { Divider } from '@mui/material';

import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { useMode } from "../../components/layout/MainLayout";

type Props = {};

const HashPage = (props: Props) => {
  const [hashData, setHashData] = React.useState<ApiAnswer<HashData>>();
  const user = useContext(UserContext);    
  const { notifyMessage } = useMode();

  /*
  React.useEffect(() => {
    if (hashData) {
      console.log(hashData);
    }
  }, [hashData]);
  */
 
  return (
    <>
      <h1>Hash Utilities</h1>
      <Divider />
      <p>
        Compute multiple different hashes for your input.
      </p>

      <div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            const obj = Object.fromEntries(new FormData(event.currentTarget));
            postHashData(user, obj, setHashData, notifyMessage);
          }}
        >
          <div>
            <Box component={Paper} >
              <TextField
                placeholder="Insert your message..."
                multiline
                rows={1}
                sx={{ width: "100%" }}
                name="hash"
              />
            </Box>
          </div>
          <Button type="submit" variant="contained">Compute</Button>
        </form>
      </div>

      <div>
        <h2>Hash</h2>
        <TableContainer component={Paper} sx={{ maxWidth: "1300px" }}>
          <Table sx={{ minWidth: 200 }} aria-label="simple table">
            <TableBody>
              <TableRow key={"row_md5"}>
                <TableCell>MD5</TableCell>
                <TableCell><Clip value={hashData?.data?.md5} /></TableCell>
              </TableRow>
              <TableRow key={"row_sha1"}>
                <TableCell>SHA-1</TableCell>
                <TableCell><Clip value={hashData?.data?.sha1} /></TableCell>
              </TableRow>
              <TableRow key={"row_sha224"}>
                <TableCell>SHA-224</TableCell>
                <TableCell><Clip value={hashData?.data?.sha224} /></TableCell>
              </TableRow>
              <TableRow key={"row_sha256"}>
                <TableCell>SHA-256</TableCell>
                <TableCell><Clip value={hashData?.data?.sha256} /></TableCell>
              </TableRow>
              <TableRow key={"row_sha284"}>
                <TableCell>SHA-384</TableCell>
                <TableCell><Clip value={hashData?.data?.sha384} /></TableCell>
              </TableRow>
              <TableRow key={"row_sha512"}>
                <TableCell>SHA-512</TableCell>
                <TableCell><Clip value={hashData?.data?.sha512} /></TableCell>
              </TableRow>
              <TableRow key={"row_crc16"}>
                <TableCell>CRC-16</TableCell>
                <TableCell><Clip value={hashData?.data?.crc16} /></TableCell>
              </TableRow>
              <TableRow key={"row_crc32"}>
                <TableCell>CRC-32</TableCell>
                <TableCell><Clip value={hashData?.data?.crc32} /></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>

  );
};

export default HashPage;