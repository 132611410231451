import React from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';

import { postWebData } from "../../comm/ApiComm";
import Clip from '../../components/Clip';

import { Checkbox, Divider, FormControlLabel, List, MenuItem, Select, Stack, ToggleButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { WebResponseData } from '../../models/WebResponseData';
import { ApiAnswer } from '../../models/ApiAnswer';
import { SelectChangeEvent } from '@mui/material/Select';

import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { useMode } from "../../components/layout/MainLayout";

import SwapVertIcon from '@mui/icons-material/SwapVert';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import * as ApiComm from "../../comm/ApiComm";
import { ArrowDownward } from '@mui/icons-material';

type Props = {};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

const WebPage = (props: Props) => {
  const [webResponse, setWebResponse] = React.useState<ApiAnswer<WebResponseData>>();
  const [webData, setWebData] = React.useState({
    url: "",
    type: "",
    payload: "",
    method: "get"
  });

  const [tab, setTab] = React.useState("request");

  const [a, setA] = React.useState<string>("");
  const [b, setB] = React.useState<string>("");

  const [url, setUrl] = React.useState<string>("");
  const [payload, setPayload] = React.useState<string>("");
  const [type, setType] = React.useState<string>("text");
  const [method, setMethod] = React.useState<string>("get");

  const [alabel, setALabel] = React.useState<string>("Text");
  const [blabel, setBLabel] = React.useState<string>("Base64");

  const [direction, setDirection] = React.useState<boolean>(true);

  const user = useContext(UserContext);
  const { notifyMessage } = useMode();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const handleTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setA(e.target.value);
  }

  const handleBase64Changed = (e: React.ChangeEvent<HTMLInputElement>) => {
    setB(e.target.value);
  }

  const handleConvert = () => {
    try {
      if (direction) {
        setB(btoa(a));
      } else {
        setA(atob(b));
      }
    } catch (e: any) {
      notifyMessage(e.message, ApiComm.CALLBACK_CODE_ERROR);
    }
  }

  const handleMethodChange = (event: SelectChangeEvent) => {
    setMethod(event.target.value as string);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  const handleSwap = () => {
    setDirection(!direction);
  }

  const handleSendClick = () => {
    postWebData(user, webData, setWebResponse, notifyMessage);
  }

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  }

  const handlePayloadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPayload(e.target.value);
  }

  React.useEffect(() => {
    if(url) {
      setWebData({ ...webData, url: url });
    }
  }, [url]);

  React.useEffect(() => {
    if(type) {
      setWebData({ ...webData, type: type });
    }
  }, [type]);

  React.useEffect(() => {
    if(method) {
      setWebData({ ...webData, method: method });
    }
  }, [method]);

  React.useEffect(() => {
    if(payload) {
      setWebData({ ...webData, payload: payload });
    }
  }, [payload]);

  React.useEffect(() => {
    /*
    if (direction) {
      setALabel("Text");
      setBLabel("Base64");
    } else {
      setALabel("Base64");
      setBLabel("Text");
    }
    */

  }, [direction]);

  React.useEffect(() => {
   
  });

  const getBase64Tab = () => {
    return (
      <>
        <div>
          <Typography variant="h6">{alabel}</Typography>
          <Divider />
          <Grid container spacing={2} sx={{ paddingTop: "1em", paddingBottom: "1em" }}>
            <Grid size={12}>
              <TextField name="string_input" defaultValue={a} value={a} onChange={handleTextChanged} sx={{ width: "100%" }} />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ paddingTop: "1em", paddingBottom: "1em" }}>
            <Grid size={3} >
              <Button variant="outlined" onClick={handleSwap} sx={{ marginRight: "1em" }}>{ direction ? <ArrowDownward /> : <ArrowUpwardIcon /> }</Button>
              <Button variant="contained" onClick={handleConvert}>Convert</Button>
            </Grid>
            <Grid size={9}>
            </Grid>
          </Grid>

          <Typography variant="h6">{blabel}</Typography>
          <Divider />
          <Grid container spacing={2} sx={{ paddingTop: "1em", paddingBottom: "1em" }}>
            <Grid size={12} >
              <TextField name="string_base64" defaultValue={a} value={b} onChange={handleBase64Changed} sx={{ width: "100%" }} />
            </Grid>
          </Grid>
        </div>
      </>
    )
  }

  const getRequestTab = () => {
    return (
      <>
        <div>
          
          {!user.user ? "You have to be logged in to send requests!" : ""}

          <Typography variant="h6">1. URL</Typography>
          <Divider />
          <Grid container spacing={2} sx={{ paddingTop: "1em", paddingBottom: "1em" }}>
            <Grid size={12}>
              <TextField name="string_input" defaultValue="Leave blank for mirroring..." value={url} onChange={handleUrlChange} sx={{ width: "100%" }} />
            </Grid>
          </Grid>

          <Typography variant="h6">2. Type</Typography>
          <Divider />
          <Grid container spacing={2} sx={{ paddingTop: "1em", paddingBottom: "1em" }}>
            <Grid size={12}>
              <Select value={method} onChange={handleMethodChange}>
                <MenuItem value="get">GET</MenuItem>
                <MenuItem value="post">POST</MenuItem>
              </Select>
            </Grid>
          </Grid>

          <Typography variant="h6">3. Payload</Typography>
          <Divider />
          <Grid container spacing={2} sx={{ paddingTop: "1em", paddingBottom: "1em" }}>
            <Grid size={12}>
              <TextField name="string_input" value={payload} onChange={handlePayloadChange} sx={{ width: "100%" }} />
            </Grid>
          </Grid>

          <Button type="submit" variant="contained" onClick={handleSendClick} disabled={user.user == null} >Send</Button>
          

          <Grid container spacing={2} sx={{ paddingTop: "1em", paddingBottom: "1em" }}>
            <Grid size={6} >
            <Typography variant="h6">Response Data</Typography>
            </Grid>
            <Grid size={6} >
            <Typography variant="h6">Response Headers</Typography>
            </Grid>
          </Grid>          
          <Divider />
          <Grid container spacing={2} sx={{ paddingTop: "1em", paddingBottom: "1em" }}>
            <Grid size={3} >
              <p>{webResponse?.data?.response}</p>
            </Grid>
            <Grid size={9} >
              <pre>{ webResponse?.data?.headers ? JSON.stringify(JSON.parse(webResponse?.data?.headers), null, 2) : "" }</pre>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }

  return (
    <>
      <h1>Web Utilities</h1>
      <Divider />
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="tabs">            
            <Tab label={"Base64"} value={"base64"} />        
            <Tab label={"HTTP Request"} value={"request"} />
          </TabList>
        </Box>

        <TabPanel value={"base64"}>
          {getBase64Tab()}
        </TabPanel>
        <TabPanel value={"request"}>
          {getRequestTab()}
        </TabPanel>

      </TabContext>
    </>

  );
};

export default WebPage;