import axios from 'axios';
import React from 'react';

import { TextData } from '../models/TextData';
import { ApiAnswer } from '../models/ApiAnswer';
import { HashData } from '../models/HashData';
import { JsonData } from '../models/JsonData';
import { ColorData } from '../models/ColorData';
import { EncodingData } from '../models/EncodingData';
import { UserCustomData } from '../models/UserCustomData';
import { RandomizerData } from '../models/RandomizerData';
import { WebResponseData } from '../models/WebResponseData';
import { BinaryData } from '../models/BinaryData';
import { UserOtherData } from '../models/UserOtherData';

const headers = (user: any) => {    
    var apiKey = "";
    
    if(user && user.user && user.user.customData && user.user.customData.api_key) {
        apiKey = user.user.customData.api_key;
    } else if(user && user.customData && user.customData.api_key) {
        apiKey = user.customData.api_key;
    }
    
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'api-key': apiKey
        }
    };
}

var API_SERVER = "https://api.develslave.com";
//var API_SERVER = "http://localhost:3001";

var apiPrefix = "/api/v1";

const ENDCODING = "encoding";
const BINARY = "binary";
const TEXT = "text";
const HASH = "hash";
const JSON = "json";
const COLOR = "color";
const NETWORK = "network";
const USER = "user";
const RANDOMIZER = "randomizer";
const WEB = "web";
const AUTH = "auth";
const GOOGLE = "google";

export const CALLBACK_CODE_INFO = 0;
export const CALLBACK_CODE_SUCCESS = 1;
export const CALLBACK_CODE_WARNING = 2;
export const CALLBACK_CODE_ERROR = 3;

export const WORKING_MESSAGE = "Working on it...";
export const DONE_MESSAGE = "Done";

export const postEncodingData = async (user: any, payload: any, clb: React.Dispatch<React.SetStateAction<ApiAnswer<EncodingData> | undefined>>, notifClb? : (message: string, type: number) => void) => {    
    if(notifClb) {
        notifClb(WORKING_MESSAGE, CALLBACK_CODE_INFO);
    }

    return axios.post(API_SERVER + apiPrefix + "/" + ENDCODING, payload, headers(user))
    .then((res) => clb(res.data))
    .then((r) => notifClb ? notifClb(DONE_MESSAGE, CALLBACK_CODE_SUCCESS) : {})
    .catch(e => notifClb ? notifClb(e.message, CALLBACK_CODE_ERROR) : {});    
}

export const postTextData = async (user: any, payload: any, clb: React.Dispatch<React.SetStateAction<ApiAnswer<TextData> | undefined>>, notifClb? : (message: string, type: number) => void) => {
    if(notifClb) {
        notifClb(WORKING_MESSAGE, CALLBACK_CODE_INFO);
    }

    return axios.post(API_SERVER + apiPrefix + "/" + TEXT, payload, headers(user))
    .then((res) => clb(res.data))
    .then((r) => notifClb ? notifClb(DONE_MESSAGE, CALLBACK_CODE_SUCCESS) : {})
    .catch(e => notifClb ? notifClb(e.message, CALLBACK_CODE_ERROR) : {});    
}

export const postHashData = async (user: any, payload: any, clb: React.Dispatch<React.SetStateAction<ApiAnswer<HashData> | undefined>>, notifClb? : (message: string, type: number) => void) => {
    if(notifClb) {
        notifClb(WORKING_MESSAGE, CALLBACK_CODE_INFO);
    }

    return axios.post(API_SERVER + apiPrefix + "/" + HASH, payload, headers(user))    
    .then((res) => res.data.code == 200 ? clb(res.data) : (notifClb ? notifClb(res.data.message, CALLBACK_CODE_ERROR) : {}))    
    .then((r) => notifClb ? notifClb(DONE_MESSAGE, CALLBACK_CODE_SUCCESS) : {})
    .catch(e => notifClb ? notifClb(e.message, CALLBACK_CODE_ERROR) : {});    
}

export const postJsonData = async (user: any, payload: any, clb: React.Dispatch<React.SetStateAction<ApiAnswer<JsonData> | undefined>>, notifClb? : (message: string, type: number) => void) => {
    if(notifClb) {
        notifClb(WORKING_MESSAGE, CALLBACK_CODE_INFO);
    }

    return axios.post(API_SERVER + apiPrefix + "/" + JSON, payload, headers(user))
    .then((res) => clb(res.data))
    .then((r) => notifClb ? notifClb(DONE_MESSAGE, CALLBACK_CODE_SUCCESS) : {})
    .catch(e => notifClb ? notifClb(e.message, CALLBACK_CODE_ERROR) : {});    
}

export const postColorData = async (user: any, payload: any, clb: React.Dispatch<React.SetStateAction<ApiAnswer<ColorData> | undefined>>, notifClb? : (message: string, type: number) => void) => {
    if(notifClb) {
        notifClb(WORKING_MESSAGE, CALLBACK_CODE_INFO);
    }

    return axios.post(API_SERVER + apiPrefix + "/" + COLOR, payload, headers(user))
    .then((res) => clb(res.data))
    .then((r) => notifClb ? notifClb(DONE_MESSAGE, CALLBACK_CODE_SUCCESS) : {})
    .catch(e => notifClb ? notifClb(e.message, CALLBACK_CODE_ERROR) : {});    
}

export const postUserData = async (user: any, payload: any, clb?: React.Dispatch<React.SetStateAction<ApiAnswer<UserCustomData> | undefined>>, notifClb? : (message: string, type: number) => void) => {
    if(notifClb) {
        notifClb(WORKING_MESSAGE, CALLBACK_CODE_INFO);
    }
    
    return axios.post(API_SERVER + apiPrefix + "/" + USER, payload, headers(user))
    .then((res) => clb ? clb(res.data) : {})
    .then((r) => notifClb ? notifClb(DONE_MESSAGE, CALLBACK_CODE_SUCCESS) : {})
    .catch(e => notifClb ? notifClb(e.message, CALLBACK_CODE_ERROR) : {});    
}


export const postRandomizerData = async (user: any, payload: any, clb?: React.Dispatch<React.SetStateAction<ApiAnswer<RandomizerData> | undefined>>, notifClb? : (message: string, type: number) => void) => {
    if(notifClb) {
        notifClb(WORKING_MESSAGE, CALLBACK_CODE_INFO);
    }
    
    return axios.post(API_SERVER + apiPrefix + "/" + RANDOMIZER, payload, headers(user))
    .then((res) => clb ? clb(res.data) : {})
    .then((r) => notifClb ? notifClb(DONE_MESSAGE, CALLBACK_CODE_SUCCESS) : {})
    .catch(e => notifClb ? notifClb(e.message, CALLBACK_CODE_ERROR) : {});    
}

export const postWebData = async (user: any, payload: any, clb?: React.Dispatch<React.SetStateAction<ApiAnswer<WebResponseData> | undefined>>, notifClb? : (message: string, type: number) => void) => {
    if(notifClb) {
        notifClb(WORKING_MESSAGE, CALLBACK_CODE_INFO);
    }
    
    return axios.post(API_SERVER + apiPrefix + "/" + WEB, payload, headers(user))
    .then((res) => clb ? clb(res.data) : {})
    .then((r) => notifClb ? notifClb(DONE_MESSAGE, CALLBACK_CODE_SUCCESS) : {})
    .catch(e => notifClb ? notifClb(e.message, CALLBACK_CODE_ERROR) : {});    
}

export const postBinaryData = async (user: any, payload: any, clb: React.Dispatch<React.SetStateAction<ApiAnswer<BinaryData> | undefined>>, notifClb? : (message: string, type: number) => void) => {
    if(notifClb) {
        notifClb(WORKING_MESSAGE, CALLBACK_CODE_INFO);
    }

    return axios.post(API_SERVER + apiPrefix + "/" + BINARY, payload, headers(user))    
    .then((res) => res.data.code == 200 ? clb(res.data) : (notifClb ? notifClb(res.data.message, CALLBACK_CODE_ERROR) : {}))    
    .then((r) => notifClb ? notifClb(DONE_MESSAGE, CALLBACK_CODE_SUCCESS) : {})
    .catch(e => notifClb ? notifClb(e.message, CALLBACK_CODE_ERROR) : {});    
}

export const getUserOtherData = async (user: any, clb: React.Dispatch<React.SetStateAction<ApiAnswer<UserOtherData> | undefined>>, notifClb? : (message: string, type: number) => void) => {
    if(notifClb) {
        notifClb(WORKING_MESSAGE, CALLBACK_CODE_INFO);
    }

    console.log(headers(user));

    return axios.post(API_SERVER + apiPrefix + "/" + USER + "/data", {}, headers(user))    
    .then((res) => res.data.code == 200 ? clb(res.data) : (notifClb ? notifClb(res.data.message, CALLBACK_CODE_ERROR) : {}))    
    .then((r) => notifClb ? notifClb(DONE_MESSAGE, CALLBACK_CODE_SUCCESS) : {})
    .catch(e => notifClb ? notifClb(e.message, CALLBACK_CODE_ERROR) : {});    
}