import React, { useContext } from 'react';

import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import { postColorData } from "../../comm/ApiComm";
import { ApiAnswer } from '../../models/ApiAnswer';
import { ColorData } from '../../models/ColorData';

import { UserContext } from '../../contexts/UserContext';
import { useMode } from '../../components/layout/MainLayout';

import { HexColorPicker } from "react-colorful";
import Clip from '../../components/Clip';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import { getKnownColors } from './data/Colors';
import { Label } from '@mui/icons-material';

type Props = {};

const ColorsPage = (props: Props) => {
  const [colorData, setColorData] = React.useState<ApiAnswer<ColorData>>();
  const user = useContext(UserContext);
  const { notifyMessage } = useMode();

  const [color, setColor] = React.useState<string>('#fff');
  const [rgb, setRgb] = React.useState<string>("");
  const [hsv, setHsv] = React.useState<string>("");
  const [hls, setHls] = React.useState<string>("");
  const [tab, setTab] = React.useState("color");

  React.useEffect(() => {
    if (color) {
      setColor(color);
    }
  }, [color]);

  const mouseUpHandle = () => {
    postColorData(user, { "color": color }, setColorData);
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const getColorTab = () => {
    return (
      <>
        <div>
          <Box>
            <HexColorPicker color={color} onChange={setColor} onMouseUp={mouseUpHandle} />
          </Box>
        </div>

        <div>
          <TableContainer component={Paper} sx={{ maxWidth: "1300px" }}>
            <Table sx={{ minWidth: 200 }} aria-label="simple table">
              <TableBody>
                <TableRow key={"row_hex"}>
                  <TableCell>HEX</TableCell>
                  <TableCell><Clip value={color} /></TableCell>
                </TableRow>
                <TableRow key={"row_sha1"}>
                  <TableCell>RGB</TableCell>
                  <TableCell><Clip value={colorData?.data?.rgb} /></TableCell>
                </TableRow>
                <TableRow key={"row_rgbf"}>
                  <TableCell>RGB float</TableCell>
                  <TableCell><Clip value={colorData?.data?.rgbf} /></TableCell>
                </TableRow>
                <TableRow key={"row_hsv"}>
                  <TableCell>HSV</TableCell>
                  <TableCell><Clip value={colorData?.data?.hsv} /></TableCell>
                </TableRow>
                <TableRow key={"row_hls"}>
                  <TableCell>HLS</TableCell>
                  <TableCell><Clip value={colorData?.data?.hls} /></TableCell>
                </TableRow>
                <TableRow key={"row_complementary"}>
                  <TableCell>Complementary</TableCell>
                  <TableCell>
                    <div style={{ height: "20px", width: "100px", background: colorData?.data?.complementaryHex }}></div>
                    <Clip value={colorData?.data?.complementaryHex} />
                  </TableCell>
                </TableRow>
                <TableRow key={"row_matching3"}>
                  <TableCell>Matching (3 colors)</TableCell>
                  <TableCell>
                    {
                      colorData?.data?.matchingColors3?.map(c => <>
                        <div style={{ height: "20px", width: "100px", background: c }}></div>
                        <Clip value={c} />
                      </>)
                    }
                  </TableCell>
                </TableRow>
                <TableRow key={"row_matching4"}>
                  <TableCell>Matching (4 colors)</TableCell>
                  <TableCell>
                    {
                      colorData?.data?.matchingColors4?.map(c => <>
                        <div style={{ height: "20px", width: "100px", background: c }}></div>
                        <Clip value={c} />
                      </>)
                    }
                  </TableCell>
                </TableRow>
                <TableRow key={"row_matching5"}>
                  <TableCell>Matching (5 colors)</TableCell>
                  <TableCell>
                    {
                      colorData?.data?.matchingColors5?.map(c => <>
                        <div style={{ height: "20px", width: "100px", background: c }}></div>
                        <Clip value={c} />
                      </>)
                    }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>)
  }

  const getKnownColorsTab = () => {
    return (
      <>
        <TableContainer component={Paper} sx={{ maxWidth: "1300px" }}>
          <Table sx={{ minWidth: 200 }} aria-label="simple table">
            <TableBody>
              <TableRow key={"row_hex"}>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>HEX</TableCell>
                <TableCell>RGB</TableCell>
              </TableRow>
              {
                getKnownColors().map((c, index) => (
                  <TableRow key={"row_" + index}>
                    <TableCell style={{ background: c.hex }}></TableCell>
                    <TableCell><Clip value={c.name} /></TableCell>
                    <TableCell><Clip value={c.hex} /></TableCell>
                    <TableCell><Clip value={"" + c.r + "," + c.g + "," + c.b} /></TableCell>
                  </TableRow>
                ))
              }

            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }


  return (
    <>
      <h1>Color Utilities</h1>
      <Divider />

      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="tabs">
            <Tab label={"Color"} value={"color"} />
            <Tab label={"Known colors"} value={"knownColors"} />
          </TabList>
        </Box>

        <TabPanel value={"color"}>
          {getColorTab()}
        </TabPanel>
        <TabPanel value={"knownColors"}>
          {getKnownColorsTab()}
        </TabPanel>

      </TabContext>



    </>
  );
};

export default ColorsPage;