import React, { useContext } from 'react';
import XMLViewer from 'react-xml-viewer'

import { UserContext } from '../../contexts/UserContext';
import { useMode } from '../../components/layout/MainLayout';
import { Divider, Typography } from '@mui/material';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';

type Props = {};

const XmlPage = (props: Props) => {

  const [xml, setXml] = React.useState<string>("");

  const user = useContext(UserContext);
  const { notifyMessage } = useMode();

  const handleXmlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setXml(e.target.value);
  }

  return (
    <>
      <h1>XML Utilities</h1>
      <Divider />
      <div>
        <Grid container spacing={2} sx={{ paddingTop: "1em", paddingBottom: "1em" }}>
          <Grid size={6}>
            <Typography variant="h6">Raw Text</Typography>
            <TextField
              placeholder="Paste your XML document..."
              multiline
              rows={20}
              sx={{ width: "100%" }}
              name="string"
              onChange={handleXmlChange}
            />
          </Grid>
          <Grid size={6}>
            <Typography variant="h6">Formatted XML</Typography>
            <XMLViewer xml={xml} />
          </Grid>
        </Grid>

      </div>
    </>

  );
};

export default XmlPage;